<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-multicast"
      title="DMS Import Batches"
      color="secondary"
      style="background-color: #FAFAFA"
    >
      <v-row dense>
        <v-col>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-card-title>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="items"
              :search="search"
              :items-per-page="50"
              :sort-by="['name']"
            >
            <template #item.created_at="{value}">
              {{ formatIsoDate(value, true) }}
            </template>       
            <template #item.rows_imported="{value}">
              {{ formatNumber(value, true) }}
            </template>       
            <template #item.rows_added="{value}">
              {{ formatNumber(value, true) }}
            </template>       
            <template #item.rows_errored="{value}">
              {{ formatNumber(value, true) }}
            </template>       
            
            
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </base-material-card>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="#FF5E40"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
  </v-container>
</template>
<script>
  import NetworkService from '@/services/NetworkService.js'
  import DmsService from '@/services/DmsService.js'  
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import { mapGetters } from 'vuex'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'
  import titleMixin from '@/mixins/titleMixin.js'

  export default {
    name: 'DmsImportBatches',
    components: {
      ErrorMessage,
    },
    mixins: [formatterMixin, navigationMixin, titleMixin],
    data () {
      return {
        showErrorDialog: false,
        error: '',
        loadingMessage: 'Loading',
        search: '',
        loading: false,
        expanded: [],
        singleExpand: false,
        valid: true,
        items: [],
        showAlert: false,
        headers: [
          { text: 'File Type', value: 'file_type' },
          { text: 'Created', value: 'created_at', align: 'center' },
          { text: '# Imported', value: 'rows_imported', align: 'center' },
          { text: '# Added', value: 'rows_added', align: 'center' },
          { text: '# Errored', value: 'rows_errored', align: 'center' },
          { text: 'Status', value: 'last_event', align: 'center' },
          { text: 'Filename', value: 'filename', align: 'center' },
        ],
      }
    },
    computed: {
      ...mapGetters(['accountId', 'accountName']),
    },
    watch: {
      accountId () {
        this.loadImportBatches()
      },
    },
    created () {
      this.loadImportBatches()
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      loadImportBatches: function () {
        this.loading = true
        DmsService.getImportBatches()
          .then(response => {
            this.items = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
    },
  }
</script>
